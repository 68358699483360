import styled from "styled-components"
import { device } from "breakpoints"

export const SearchFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  background: #ccc;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 25px;
  width: 100%;

  @media ${device.desktop} {
    flex-direction: row;
    background: #fff;
  }
`
export const TriggerAndResults = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: none;
  height: 60px;

  @media ${device.desktop} {
    height: auto;
  }

  h4 {
    font-size: 18px;
    @media ${device.desktop} {
      display: none;
    }
  }
`

export const ResultsCount = styled.p`
  font-size: 14px;
  padding: 0 25px;
  height: 100%;
  display: flex;
  align-items: center;

  span {
    font-weight: 600;
    color: black;
    margin-right: 4px;
  }
`

export const Filters = styled.ul`
  display: ${(props) => (props.filtersShown ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transform: translateY(100%);
  border-radius: inherit;
  background: #ffffff;
  box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(210, 212, 224, 0.7);

  @media ${device.desktop} {
    max-width: 100%;
    flex-direction: row;
    box-shadow: none;
    width: auto;
    position: static;
    transform: none;
    border: none;
    display: flex;
    order: -1;
  }
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
`

export const Checkbox = styled.span`
  /* styling the box */
  position: relative;
  background: #ffffff;
  border: 1px solid ${(props) => (props.selected ? "red" : "#d2d4e0")};
  width: 30px;
  height: 30px;
  border-radius: 4px;
  transition: 100ms;
  margin-right: 15px;

  /* styling the tick */
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    transition: 100ms;
    width: 9px;
    height: 15px;
    z-index: 3;
    border: solid green;
    border-width: 0 3px 3px 0;
    transform: ${(props) =>
      props.selected
        ? "translate3d(-50%, -60%, 0) scale(1) rotate(45deg)"
        : "translate3d(-50%, -40%, 0) scale(0.3) rotate(45deg)"};
  }
`

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: 100ms;
  padding: 20px;
  background: ${(props) => props.selected && "#F6F8FA"};
  border-bottom: 1px solid #ddd;

  @media ${device.desktop} {
    width: auto;
    border-bottom: none;
    border-right: 1px solid #ddd;
  }

  :last-child {
    border-bottom: none;
  }

  /* dont touch */
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;

    ::-ms-check {
      display: none;
    }
  }
`
