import React, { useState } from "react"
import {
  SearchFiltersContainer,
  TriggerAndResults,
  ResultsCount,
  Filters,
} from "./style"
import Filter from "./Filter"

const filtersList = [
  {
    label: "Art",
    value: "art",
  },
  {
    label: "Architecture",
    value: "architecture",
  },
  {
    label: "Branding",
    value: "branding",
  },
  {
    label: "Illustration",
    value: "illustration",
  },
]

const SearchFilters = ({
  filters,
  setFilters,
  totalPosts,
  emptyQuery,
  currentPage,
  setCurrentPage,
}) => {
  const [filtersShown, setFiltersShown] = useState(false)

  return (
    <SearchFiltersContainer>
      <TriggerAndResults
        filtersShown={filtersShown}
        onClick={() => setFiltersShown(!filtersShown)}
      >
        <h4>Filters</h4>

        {!emptyQuery ? (
          <ResultsCount>
            <span>{totalPosts > 0 ? totalPosts : 0}</span>results found.
          </ResultsCount>
        ) : null}

        {/* <Arrow /> */}
      </TriggerAndResults>

      <Filters filtersShown={filtersShown}>
        {filtersList.map((filter, index) => {
          return (
            <Filter
              key={index}
              setFilters={setFilters}
              filters={filters}
              label={filter.label}
              value={filter.value}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )
        })}
      </Filters>
    </SearchFiltersContainer>
  )
}

export default SearchFilters
