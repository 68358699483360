import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Search } from "screens/Search"
const search = ({ pageContext }) => {
  return (
    <Layout title="Search" pageContext={pageContext}>
      <SEO title="Search" />
      <Search />
    </Layout>
  )
}

export default search
