import React, { useState, useEffect } from "react"
import { FilterItem, Checkbox, Label } from "./style"

const Filter = ({
  filters,
  setFilters,
  currentPage,
  setCurrentPage,
  label,
  value,
  // pushToHistory,
}) => {
  const [selected, setSelected] = useState(false)

  const handleSelect = () => {
    setSelected(!selected)
    setCurrentPage(1)

    const updatedFilters = filters.filter((item) => item !== value)

    if (!selected) {
      setFilters([...updatedFilters, value])
    } else {
      setFilters([...updatedFilters])
    }
  }

  useEffect(() => {
    if (filters.includes(value)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [filters, value])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search)
      const filtersParam = urlParams?.get("f")?.split("|")

      if (filtersParam?.includes(value)) {
        setSelected(true)
      }
    }
  }, [value])

  return (
    <FilterItem selected={selected}>
      <input
        // name={value}
        // id={value}
        onChange={() => handleSelect()}
        type="checkbox"
      />
      <Checkbox selected={selected} />

      <Label htmlFor={value}>
        <h4>{label}</h4>
      </Label>
    </FilterItem>
  )
}

export default Filter
