import styled from "styled-components"
// import { device } from "breakpoints"
import { Teaser } from "ui/Teaser"
import { Grid } from "ui/Grid"

export const SearchTemplateContainer = styled.div``

export const SearchResultTeaser = styled(Teaser)``

export const SearchResultsGrid = styled(Grid)`
  /* border: 2px dashed teal; */
`
