import React, { useState, useEffect, useCallback } from "react"
import {
  SearchTemplateContainer,
  SearchResultTeaser,
  SearchResultsGrid,
} from "./style"
import { MainColumn } from "ui/MainColumn"
import { useDidMountEffect } from "helpers/useDidMountEffect"
import algoliaIndex from "./algoliaIndex"
import { Row, Col } from "ui/Grid"

import { Pagination } from "ui/Pagination"
import SearchFilters from "./SearchFilters/SearchFilters"
import history from "ui/Pagination/history"

const Search = () => {
  const [query, setQuery] = useState("")
  const [filters, setFilters] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPosts, setCurrentPosts] = useState(null)
  const [totalPosts, setTotalPosts] = useState(null)
  const [totalPages, setTotalPages] = useState(0)

  const postsPerPage = 9

  const emptyQuery = !query.length && filters.length === 0

  const findResults = useCallback(() => {
    algoliaIndex
      .search(query, {
        page: currentPage - 1,
        hitsPerPage: postsPerPage,
        facetFilters: [filters.map((filter) => `categories: ${filter}`)],
      })
      .then((data) => {
        setCurrentPosts(data.hits)
        setTotalPosts(data.nbHits)
        setTotalPages(data.nbPages)
      })
  }, [currentPage, query, filters])

  const pushToHistory = (page, filters) => {
    const filtersString = filters?.length
      ? filters
          .map((filter, index) => {
            if (index !== filters.length - 1) return `${filter}|`
            else return filter
          })
          .join("")
      : ""

    // TO DO
    // Instead of history.push, add the filter url to localStorage
    // In gatsby-browser.js if the path change is the search page, then grab url and push it into history
    history.push(
      {
        pathname: `${window.location.pathname}`,
        search: `?p=${page}${query ? `&q=${query}` : ""}&f=${filtersString}`,
      },
      { ...history.location.state, page: page, query: query, filters: filters }
    )
  }

  useDidMountEffect(() => {
    if (emptyQuery) {
      setCurrentPosts(null)
      setTotalPosts(null)
      setTotalPages(null)
    } else {
      findResults()
    }
  }, [findResults, emptyQuery])

  //get params
  useEffect(() => {
    if (typeof window !== `undefined`) {
      const urlParams = new URLSearchParams(window.location.search)
      const queryParam = urlParams.get("q")
      const pageParam = urlParams.get("p")
      const filtersParam = urlParams?.get("f")?.split("|")

      if (queryParam) {
        setQuery(queryParam)
      }

      if (pageParam) {
        setCurrentPage(parseInt(pageParam))
      }

      if (filtersParam?.length) {
        setFilters(filtersParam)
      }
    }
  }, [])

  return (
    <SearchTemplateContainer>
      <MainColumn>
        <input
          id="searchInput"
          style={{ marginBottom: "25px" }}
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
            setCurrentPage(1)
          }}
        />

        <SearchFilters
          currentPage={currentPage}
          filters={filters}
          setFilters={setFilters}
          emptyQuery={emptyQuery}
          totalPosts={totalPosts}
          setCurrentPage={setCurrentPage}
        />

        {emptyQuery ? (
          <p>Type to initiate a search ya dawg.</p>
        ) : totalPosts > 0 ? (
          <p style={{ marginBottom: "20px" }}>
            page <strong>{currentPage}</strong> out of{" "}
            <strong>{totalPages}</strong> pages
          </p>
        ) : totalPosts === 0 ? (
          <p>Nothing came up, try harder next time.</p>
        ) : null}

        {currentPosts && (
          <SearchResultsGrid>
            <Row gap={[20, 40, 60, 80]}>
              {currentPosts.map((item, index) => {
                return (
                  <Col xs={12} md={4} key={index}>
                    <SearchResultTeaser
                      key={index}
                      title={item.title}
                      link={item.link}
                      img={{ ...item.image, aspectRatio: 1.5 }}
                    />
                  </Col>
                )
              })}
            </Row>
          </SearchResultsGrid>
        )}

        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxNumbersVisible={5}
          totalPosts={totalPosts}
          postsPerPage={postsPerPage}
          query={query}
          setQuery={setQuery}
          filters={filters}
          setFilters={setFilters}
          pushToHistory={pushToHistory}
          scrollId="header"
        />
      </MainColumn>
    </SearchTemplateContainer>
  )
}

export default Search
